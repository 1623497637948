import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClock, faComment, faGlasses, faCheckCircle, faHeart } from '@fortawesome/free-solid-svg-icons';

function MidBlockNews() {
  var [Articles, updateArticles] = useState<any[]>([])

  useEffect(() => {
    var userId = ""
        if(localStorage.getItem("userId") != null){
            userId = "&user=" + localStorage.getItem("userId")
        }
    axios
      .get("https://playstationcommunity.hu/wp-json/kingweb/v1/id?type=page&limit=10&page=1" + userId)
      .then((res) => {
        updateArticles(res.data)

        console.log(res.data)
      })

  }, [])

    return (
      <div>
        
                    {Articles.map((val, key) => {
                        function watched(): React.ReactNode {
                            if(val.isWatched){
                                    return (<p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faCheckCircle} /></p>)
                            }
                            
                        }
                        function faved(): React.ReactNode {
                            if(val.isFav){
                                    return (<p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faHeart} /></p>)
                            }
                            
                        }
                      const cimke = (): string =>  {
                        var titleof = ""
                        if(val.fields.cimke_oldalon_lathato !== undefined) {
                            titleof = val.fields.cimke_oldalon_lathato.split(":")[0]
                        }
                        return titleof
                    }
                    const cimkeColor = (): string =>  {
                        var titleof1 = ""
                        if(val.fields.cimke_oldalon_lathato !== undefined) {
                            titleof1 = val.fields.cimke_oldalon_lathato.split(":")[1]
                        } else {
                            titleof1 = "rgba(0,0,0,0)"
                        }
                        return titleof1
                    }
                        return (
                            <div className="articlesBlockHome">
                                       <Link to={{ pathname: '/' + val.slug }}>
                                        <div className='artoBlockHome'>
                                            <p className="ribbon-2" style={{backgroundColor: cimkeColor(), fontSize: "13px", color: "white", padding: "5px"}}>{cimke()}</p>
                                            <div className="topofHome">
                                                <div className='newsTopInfos newsTopInfosHome'> 
                                                
                                                    <div className='newsTopTitle'><h5>{val.title.rendered}</h5></div>
                                                    <h6 className='subtitle'>{val.excerpt.rendered}</h6>
                                                    <div className='additionalText'>
                                                        <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faUser} />{val.author_meta.display_name}</p>
                                                        <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faClock} />{val.formatted_date}</p>
                                                        <p className='textAuthor' style={{display: val.comment_count > 0 ? 'block' : 'none'}}><FontAwesomeIcon className='faIcon' icon={faComment} />{val.comment_count}</p>
                                                        <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faGlasses} /> {val.readinminute} perc</p>
                                                        {watched()}
                                                        {faved()}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="containerHome">
                                                <div className="boxHome"> <img alt={val.title.rendered} src={val.jetpack_featured_media_url} className='newsImageHome'></img></div>
                                                <div className="boxHome overlayHome">

                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                            </div>
                            )
                        })}
            
      </div>
    )
}

export default MidBlockNews;