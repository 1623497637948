import React, { useEffect, useState} from 'react';
import axios from 'axios';
import LoadingIcons, { Puff, ThreeDots } from 'react-loading-icons'
import Swal from 'sweetalert2';

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);

    document.title = "Bejelentkezés - PlayStationCommunity.hu"
    const apiUrl = 'https://playstationcommunity.hu/';

    useEffect(() => {
      window.scrollTo(0, 0)
  }, [])
    axios.interceptors.request.use(
        config => {

          const token = localStorage.getItem('token');
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );
    const handleLogin = () => {

        setLoading(true)
        axios
    .post("https://playstationcommunity.hu/wp-json/jwt-auth/v1/token?username=" + username +"&password=" + password)
    .then((response) => {

        if(response.status === 200){
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('username', response.data.user_display_name)
            if(response.data.user_roles.includes("administrator") || response.data.user_roles.includes("editor")) {
                localStorage.setItem('isAdmin', "true")
            } else {
                localStorage.setItem('isAdmin', "false")
            }
            window.location.replace('/')
        } else {
            window.alert("Sikertelen bejelentkezés!")
            setLoading(false)
            localStorage.setItem('token', "")
        }
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: 'Mission Failed',
            text: 'Hiba a bejelentkezés során!',
            showConfirmButton: true
          })
        localStorage.setItem('token', "")
    }).finally(() => {
        setLoading(false);
    });
    }
    function LoginButton() {
        if (isLoading) {
          return <Puff stroke="#004f64" strokeOpacity={.125} />;
        }
        return <button type='button' onClick={handleLogin} className="searchButton">Bejelentkezés</button>;
      }
        return (
            <div className='loginScreen'>
                <h2>Bejelentkezés</h2>
                <form>
                    <input 
                    type="text" 
                    placeholder='Felhasználónév' 
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="searchInput loginInput" required>
                    </input>
                    <br></br>
                    <input 
                    type="password" 
                    placeholder='Jelszó' 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="searchInput loginInput" required></input>
<br></br>
{<LoginButton/>}
<a href='https://playstationcommunity.hu/wp-login.php?action=lostpassword'><button type='button' className="searchButton">Elfelejtett jelszó</button></a>
                    
                </form>
            </div>
        )

}