
import Tags from "../Home/TagsOf";
import React from 'react';

export default function Footer() {

    return (
        <div className='footer'>
            <div className="topFooter"><Tags></Tags></div>
            <div className="adaptive alogo">

                <div className="adatp"><p className="adaptive">Copyright © PlayStation.Community, Minden jog fenntartva. - <a href="https://playstationcommunity.hu/playstation-community/">Impresszum</a> - Dizájn és kivitelezés: <a href="https://kingweb.hu">KingWeb.hu</a></p></div>
            </div>
        </div>
    )
}