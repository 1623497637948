import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";


function MidBlockNews() {
  var [youtube, updateYoutube]: any = useState()
  const [isLoading, setIsLoading] = useState("true");

  useEffect(() => {
    setIsLoading("true")
    axios
      .get("https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3DUCrbN37IOtk2nlNI9RJLlsOQ")
      .then((res) => {
        updateYoutube(res.data)
        setIsLoading("false")
      })
  }, [])
  if(isLoading === "true") {
    return (
        <div>Feliratkozás</div>
    )
  } else {
    return (
        <div>
            <a href={youtube.items[0].link} target="_blank">
            <div id="containeryt">
                
            <div id="naviyt"><img src={youtube.items[0].thumbnail} style={{maxHeight: "270px", objectFit: "cover", width: "100%", opacity: "0.8"}}></img></div>
            <div id="infoiyt">
            
            <p className="youtubeNew">Legfrissebb videónk</p>
            <h4 className="titleyoutube">{youtube.items[0].title.slice(0, 40) + "..."}</h4>
            <img className="youtubeima" src="https://upload.wikimedia.org/wikipedia/commons/e/ef/Youtube_logo.png"></img>
            </div>
            </div>
            </a>
        </div>
      )
  }
    
}

export default MidBlockNews;