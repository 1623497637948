import React, { useState, useRef, useEffect } from 'react';

function Game() {
  const [playerY, setPlayerY] = useState(0);
  const [score, setScore] = useState(0);
  const obstacles: any = useRef([]);

  useEffect(() => {
    const gameLoop = setInterval(() => {
      // Move obstacles and check for collisions
      obstacles.current.forEach((obstacle: { x: number; y: number; height: any; width: any; }) => {
        obstacle.x -= 1;
        if (obstacle.x < -100) {
          obstacle.x = 800;
          setScore(score + 1);
        }
        if (
          playerY < obstacle.y + obstacle.height &&
          playerY + 50 > obstacle.y &&
          obstacle.x + obstacle.width > 0 &&
          obstacle.x < 50
        ) {
          clearInterval(gameLoop);
          alert('Game Over!');
        }
      });
    }, 10);

    return () => clearInterval(gameLoop);
  }, [playerY, score]);

  const handleKeyDown = (event: { key: string; }) => {
    if (event.key === 'ArrowUp' && playerY > 0) {
      setPlayerY(playerY - 5);
    } else if (event.key === 'ArrowDown' && playerY < 450) {
      setPlayerY(playerY + 5);
    }
  };

  return (
    <div className="game" onKeyDown={handleKeyDown} tabIndex={0}>
      <div className="player" style={{ top: playerY }} />
      {obstacles.current.map((obstacle: React.CSSProperties | undefined, index: React.Key | null | undefined) => (
        <div key={index} className="obstacle" style={obstacle} />
      ))}
      <div className="score">{score}</div>
    </div>
  );
}

export default Game;
