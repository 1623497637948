import React from "react";
import Space from "../img/space.png";
import Konami from 'react-konami-code';
import { Link } from "react-router-dom";

function NotFound() {
    const easterEgg = () => {
      }
    return (
        <div className="notFound">
            
            <img className='notFoundImage' alt="Not found" src={Space}></img>
            <h2>Hé Pajtás! Eltévedtél?</h2>
            <p>Eléggé úgy tűnik, hogy rossz helyen vagy, de ne búslakodj, biztosan hamarosan megtalálod amit keresel! </p>
            {/* <Konami>
            <Link to="/game/konami/psc"><button>Játék indítása</button></Link>
            </Konami> */}
        </div>
    );
}

export default NotFound;
