import React,{ useEffect, useState } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { faUser, faClock, faComment, faGlasses, faCheckCircle, faHeart } from '@fortawesome/free-solid-svg-icons';
import psBg from '../../img/psbg.png'

function MidBlockNews() {
  var [Articles, updateArticles] = useState<any[]>([])
  let runCount = 0;
  useEffect(() => {
    var userId = ""
        if(localStorage.getItem("userId") != null){
            userId = "&user=" + localStorage.getItem("userId")
        }
    axios
      .get("https://playstationcommunity.hu/wp-json/kingweb/v1/id?type=post&limit=10&page=1" + userId)
      .then((res) => {
        updateArticles(res.data)
      })

  }, [])

    return (
      <div>
        
                    {Articles.map((val, key) => {
                      function watched(): React.ReactNode {
                        if(val.isWatched){
                                return (<p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faCheckCircle} /></p>)
                        }
                        
                    }
                    function faved(): React.ReactNode {
                      if(val.isFav){
                              return (<p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faHeart} /></p>)
                      }
                      
                  }
                      const cimke = (): string =>  {
                        var titleof = ""
                        if(val.fields.cimke_oldalon_lathato !== undefined) {
                            titleof = val.fields.cimke_oldalon_lathato.split(":")[0]
                        }
                        return titleof
                    }
                    const cimkeColor = (): string =>  {
                        var titleof1 = ""
                        if(val.fields.cimke_oldalon_lathato !== undefined) {
                            titleof1 = val.fields.cimke_oldalon_lathato.split(":")[1]
                        } else {
                            titleof1 = "rgba(0,0,0,0)"
                        }
                        return titleof1
                    }
                        return (
                          <div>
                            {++runCount === 3 && <div key={-1}><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4858592308850935"
     crossOrigin="anonymous"></script>
<ins className="adsbygoogle"
     style={{display: "block"}}
     data-ad-format="fluid"
     data-ad-layout-key="-e5-6l+fg+dv-1bc"
     data-ad-client="ca-pub-4858592308850935"
     data-ad-slot="4305547293"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script></div>}
                            <Link to={{ pathname: '/hirek/komment/' + val.slug }}>
                              
                            <div className="newsBlockHome" key={key} >
                                
                                <div className="newsColumn-1">

                                <p className="ribbon-2" style={{backgroundColor: cimkeColor(), display: "inline-block", fontSize: "13px", color: "white", padding: "5px"}}>{cimke()}</p>
                                <img className="newsImage" alt={val.title.rendered} src={val.jetpack_featured_media_url}></img>
                                </div>
                                <div className="newsColumn-2 ">
                                <h3 className="newsHomeTitle">{val.title.rendered}</h3>
                                <h5 className="newsHomeTitle">{val.excerpt.rendered}</h5>
                                <div className='additionalText additionalTextHome'>
                                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faUser} />{val.author_meta.display_name}</p>
                                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faClock} />{val.formatted_date}</p>
                                  <p className='textAuthor' style={{display: val.comment_count > 0 ? 'block' : 'none'}}><FontAwesomeIcon className='faIcon' icon={faComment} />{val.comment_count}</p>
                                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faGlasses} /> {val.readinminute} perc</p>
                                  {watched()}
                                  {faved()}
                                  </div>
                                
                                </div>
                                <div className="bgNewsBlockHome" style={{backgroundImage: "url(" +val.jetpack_featured_media_url+")"}}></div>
                            </div>
                            </Link>
                            </div>
                            )
                        })}
            
      </div>
    )
}

export default MidBlockNews;