import React, {useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import '../../css/News.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClock, faComment, faGlasses, faHeart, faHeartBroken } from '@fortawesome/free-solid-svg-icons';
import Comments from '../Comment';
import axios from 'axios';
import LoadingSpinner from '../LoadingSpinner';
import Sidebar from '../Sidebar/Sidebar';


interface NewsContentProps {
    setArticleData: (data: any) => void; // Adjust the type accordingly
  }

export default function Content({ setArticleData }: NewsContentProps) {
    var [Page, updatePages] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false);
    const [editUrl, setEditUrl] = useState("")
    const [needReload, setneedReload] = useState(false)
    let { id } = useParams();

    const handleClickScroll = () => {
        const element = document.getElementById('comments');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    useEffect(() => {
        
        window.scrollTo(0, 0)
        setIsLoading(true)
        var userId = ""
        if(localStorage.getItem("userId") != null){
            userId = "&user=" + localStorage.getItem("userId")
        }
        axios
            .get("https://playstationcommunity.hu/wp-json/kingweb/v1/id?title=" + id + "&type=post" + userId)
            .then((res) => {
                updatePages(res.data)
                document.title = res.data[0].title.rendered + " - PlayStationCommunity.hu"
                setEditUrl("https://playstationcommunity.hu/wp-admin/post.php?post=" + res.data[0].id + "&action=edit")
                setIsLoading(false)
                setArticleData(res.data[0])
                if(localStorage.getItem('isUserLoggedIn') == "true"){
                    const data = [res.data[0].id]; 
                 axios.post("https://playstationcommunity.hu/wp-json/kingweb/v1/watched/" + localStorage.getItem("userId"), data)
                 }
            }).finally(() => {
                const script = document.createElement('script');
            script.src = "https://platform.twitter.com/widgets.js";
            script.async = true;
            document.body.appendChild(script);
          return () => {
              document.body.removeChild(script);
            }
            })
            
    }, [id, setArticleData])
    if (isLoading) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        )
    } {
        function Tags(tags: any): React.ReactNode {
            if(tags.length > 0) {
                return(
                    tags.map((val:any) => {
                        var link = "/tag/" + val.slug + "/" + val.name
                        return(
                            <Link to={link}>
                            <div className='tag'>{val.name}</div>
                            </Link>
                        )
                    })
                )
            }
            
        }
        function editButton(): React.ReactNode {
            if(localStorage.getItem('isUserLoggedIn') == "true"){
                if(localStorage.getItem('isAdmin') == "true"){
                    return (<a className="favbutton" href={editUrl}>Szerkesztés</a>)
                }
            }
            
        }
        
        return (
            <div>
                <div className='blockContent'>
                    <div>
                        {Page.map((val, key) => {
                            function handleFav() {
                                const data = [val.id]; 
                                     axios.post("https://playstationcommunity.hu/wp-json/kingweb/v1/fav/" + localStorage.getItem("userId"), data).then(response => {
                                        window.location.reload();
                                    })
                                     
                                
                            }
                            function watchlistButton(): React.ReactNode {
        
                                if(localStorage.getItem('isUserLoggedIn') == "true"){
                                    if(!val.isFav){
                                        return (<p onClick={handleFav} className="favbutton"><FontAwesomeIcon className='faIcon' icon={faHeart} /> Kedvencekhez adom</p>)
                                    } else {
                                        return (<p onClick={handleFav} className="favbutton activeFav"><FontAwesomeIcon className='faIcon' icon={faHeartBroken} /> Eltávolítás a kedvencekből</p>)
                                    }
                                }
                            }
                            return (
                                <div key={key}>
                                    <div id="container" className='newscontento'>
                                        <div id="navi">
                                            <img alt={val.title.rendered} className='content-image newscontento' src={val.jetpack_featured_media_url}></img>
                                        </div>
                                        <div id="infoi">

                                            <div className='additionalText additionalTextContent'>
                                                <Link to={{ pathname: '/tagok/' + parseInt(val.author_meta.author_id) }}>
                                                <div>
                                                    <img className="authorAvatar" alt={val.author_meta.display_name} src={val.author_meta.avatar_url}/>
                                                    <p>{val.author_meta.display_name}</p></div>
                                                </Link>
                                                <p className='timein'><FontAwesomeIcon className='faIcon' icon={faClock} />{val.formatted_date}</p>
                                                <p className='timein'><FontAwesomeIcon className='faIcon' icon={faGlasses} />{val.readinminute} perc</p>
                                                <p onClick={handleClickScroll} className='commentsNews'><FontAwesomeIcon className='faIcon' icon={faComment} />{val.comment_count}</p>
                                            </div>
                                            <center><hr></hr></center>
                                            <h1>{val.title.rendered}</h1>

                                        </div>
                                    </div>

                                    <div className='contentBody'>
                                        
                                        <div className='divone'>
                                            {watchlistButton()}
                                        {editButton()}
                                        {screen.width > 900 ? <div id='playstationcomm_cikk_pc_640x360_1'></div> : <div id='playstationcomm_cikk_mobil_2'></div>}
                                        <div className='contentone' dangerouslySetInnerHTML={{ __html: val.content }} />
                                        </div>
                                        <div className='divtwo'> <Sidebar article={val}/></div>
                                    </div>
                                    <div className='tags'>Cimkék: {Tags(val.tags)}</div>
                                    <Comments id={val.id} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

}