import React, { useEffect, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import pscLogo from "../../img/pscLogo.png"
import youtubeDark from "../../img/youtube_dark.png"
import youtubeLight from "../../img/youtube_light.png"
import tiktokDark from "../../img/tiktok_dark.png"
import tiktokLight from "../../img/tiktok_light.png"
import "../../css/navbar.scss";
import { useContext } from 'react';
import { ThemeContext } from '../../../contexts/theme-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun, faPlay } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';
import axios from "axios";
import jwt_decode from "jwt-decode";

function Navbar() {

  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [loginName, setLoginName] = useState("");
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const cookies = new Cookies();

  const { theme, setTheme } = useContext(ThemeContext);
  const handleThemeChange = () => {
    const isCurrentDark = theme === 'dark';
    setTheme(isCurrentDark ? 'light' : 'dark');
    cookies.set('theme', isCurrentDark ? 'light' : 'dark', { path: '/' });
    if (isCurrentDark === false) {
      document.body.style.backgroundColor = "rgb(25,25,25)";
    } else {
      document.body.style.backgroundColor = "rgb(255,255,255)";
    }
  };

  useEffect(() => {
    axios.post("https://playstationcommunity.hu/wp-json/jwt-auth/v1/token/validate", {
      //...data
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}` 
      }
    })
    .then((res) => {
      if(res.data.data.status === 200){
        let username: string = localStorage.getItem('username')!
        setUserName(username)
        var decoded: any = jwt_decode(localStorage.getItem('token')!);
        setLoginName(decoded.data.user.id)
        setLoggedIn(true)
        localStorage.setItem('isUserLoggedIn', "true")

        localStorage.setItem('userId', decoded.data.user.id)
      } else {
        handleLogoutWithoutRedirect()
      }
    })
    .catch((err) => {handleLogoutWithoutRedirect()});

    setTheme(cookies.get('theme'))
    if (cookies.get('theme') === 'dark') {
      document.body.style.backgroundColor = "rgb(25,25,25)";
    } else {
      document.body.style.backgroundColor = "rgb(255,255,255)";
    }
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [setTheme]);

  useEffect(() => {
    if (size.width > 1024 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    if(window.innerWidth < 800) {
      setMenuOpen((p) => !p);
    }
  };
  const handleLogout = () => {
    localStorage.setItem('isUserLoggedIn', "false")
    localStorage.setItem('token', "")
    setLoggedIn(false)
    window.location.replace('/')
  }
  const handleLogoutWithoutRedirect = () => {
    localStorage.setItem('isUserLoggedIn', "false")
    localStorage.setItem('token', "")
    setLoggedIn(false)
  }
  const profile = () => {
  if(isLoggedIn){
    if(localStorage.getItem('isAdmin') == "true"){
      return(
        <><><Link onClick={menuToggleHandler} to={{ pathname: '/tagok/' + loginName }}>
          <button className="btn">{userName}</button>
          
        </Link></><button onClick={handleLogout} className="btn btn__login">Kijelentkezés</button><a href="https://playstationcommunity.hu/wp-admin"><button className="btn btn__login">Admin</button></a></>
      )
    } else {
    return(
      <><><Link onClick={menuToggleHandler} to={{ pathname: '/tagok/' + loginName }}>
        <button className="btn">{userName}</button>
        
      </Link></><button onClick={handleLogout} className="btn btn__login">Kijelentkezés</button></>
    )}
  } else {
    return(
      <><Link onClick={menuToggleHandler} to="/regisztracio">
        <button className="btn">Regisztráció</button>
      </Link><Link onClick={menuToggleHandler} to="/bejelentkezes">
          <button className="btn btn__login">Belépés</button>
        </Link></>
    )
  }
    
  }
  return (
    <header className="header">
      
      <div className="header__content">
        
        <Link to="/" className="header__content__logo">
          <img alt="Logo" className='headerLogo' src={pscLogo}></img>
          
        </Link>
        <nav
          className={`${"header__content__nav"} 
          ${menuOpen && size.width < 1024 ? `${"isMenu"}` : ""} 
          }`}>
          <ul>
          <li>
              <Link to="https://psclassic.hu" target='_blank' className="classic">PSClassic</Link>
            </li>
            {/* <li>
              <Link onClick={menuToggleHandler} to="/kereses" ><FontAwesomeIcon className='faIcon toggleDm' icon={faMagnifyingGlass} /></Link>
            </li> */}
            <li>
              <Link onClick={menuToggleHandler} to="/">Főoldal</Link>
            </li>
            <li>
              <Link onClick={menuToggleHandler} to="/hirek">Hírek</Link>
            </li>
            <li>
              <Link onClick={menuToggleHandler} to="/cikkek">Cikkek</Link>
            </li>
            {profile()}
           
            <li className="socialli">
              <Link to="https://www.youtube.com/@hungarypsc " target='_blank' className="sociallink"><img src={theme == 'dark' ? youtubeDark : youtubeLight} className="socialbutton"/></Link>
            </li>
            <li className="socialli">
              <Link to="https://www.tiktok.com/@pschungary" target='_blank' className="sociallink"><img src={theme == 'dark' ? tiktokDark : tiktokLight} className="socialbutton"/></Link>
            </li>
            <div className="toggle-btn-section">
              <div className={`toggle-checkbox m-vertical-auto`}>
                <button type="button" className="toggleDm" onClick={handleThemeChange}>{theme === "dark" ? <FontAwesomeIcon className='faIcon toggleDm' icon={faMoon} /> : <FontAwesomeIcon className='faIcon toggleDm' icon={faSun} />}</button>
              </div>
              
            </div>

          </ul>
        </nav>
        <div className="header__content__toggle">
          {!menuOpen ? (
            <BiMenuAltRight onClick={menuToggleHandler} />
          ) : (
            <AiOutlineClose onClick={menuToggleHandler} />
          )}
        </div>
        
      </div>
      
    </header>
  );
}

export default Navbar;
