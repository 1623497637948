import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

function Profile() {
    let { id } = useParams();
    const [profiles, setProfile] = useState<any[]>([])
    const [authorNews, setNews] = useState<any[]>([])
    const [authorArticles, setArticle] = useState<any[]>([])
    const [pagination, setPagination] = useState(1)
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
        setLoading(true)
        axios.get("https://playstationcommunity.hu/wp-json/buddypress/v1/members/" + id)
        .then((res) => {
            setProfile([res.data])
            
        })
        axios.get("https://playstationcommunity.hu/wp-json/kingweb/v1/hirek?page=1&author=" + id + "&limit=10")
        .then((res) => {
            setNews(res.data)
            
        })
        axios.get("https://playstationcommunity.hu/wp-json/kingweb/v1/authorpage?author=" + id + "&limit=10&page=" + pagination)
        .then((res) => {
            setArticle(res.data)
            setLoading(false)
        })
      }, [pagination, id]);
      const handlePagination = (e: any) => {
        if(e === "next"){
            if(authorArticles.length > 9) {
                setPagination(pagination + 1)
            }
        } else {
            if(pagination !== 1){
                setPagination(pagination - 1)
            }
        }
      }
      const news = () => {
        var news: JSX.Element[] = []
        if(authorNews !== undefined && authorNews.length > 0) {
            news.push(<h4 className="title">Publikált hírei</h4>)
            authorNews.forEach(element => {
                news.push(
                    <Link to={{ pathname: '/hirek/komment/' + element.slug }}>
                    <div className="newsdiv">
                    <img className="authorNewsimage" src={element.jetpack_featured_media_url}></img>
                    <div>
                    <h5>{element.title.rendered.slice(0, 40) + "..."}</h5>
                    <p>{element.formatted_date}</p>
                    </div>
                    </div>
                    </Link>)
        })
        }
        if(authorNews.length > 0){
            return (
                <div className="half">
                {news}
                </div>
            )
        }
        
      }
      const articles = () => {
        var articles: JSX.Element[] = []
        if(authorArticles !== undefined && authorArticles.length > 0) {
            articles.push(<div className="title"><button onClick={e => handlePagination("prev")}>Előző</button><h4 >Publikált cikkei - {pagination}.oldal</h4><button onClick={e => handlePagination("next")}>Következő</button></div>)
            authorArticles.forEach(element => {
                articles.push(<Link to={{ pathname: '/' + element.slug }}>
                    <div className="newsdiv">
                    <img className="authorNewsimage" src={element.jetpack_featured_media_url}></img>
                    <div>
                    <h5>{element.title.rendered.slice(0, 40) + "..."}</h5>
                    <p>{element.formatted_date}</p>
                    </div>
                    </div>
                    </Link>)
        })
        }
        if(authorArticles.length > 0) {
            if(isLoading){
                return(
                        <div className="half">
                            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                )
            } else {
                return (
                    <div className="half">
                    {articles}
                    </div>
                )
            }
            
        }
        
      }

      const psnProfile = (e: any) => {
        if (e.xprofile.groups[1].fields[0] === undefined) {
            return (<div></div>)
        } else if (e.xprofile.groups[1].fields[0].value.raw !== "") {
            var name = e.xprofile.groups[1].fields[0].value.raw
            var ahref = "https://psnprofiles.com/" + name
            var img = "https://card.psnprofiles.com/1/" + name + ".png"
            return(<a href={ahref}><img src={img}></img></a>)
        }
      }

    return (
        <div className="profile">
            {profiles.map((val, key) => {
                document.title = val.name + " - PlayStationCommunity.hu"
                            return (
                                <div key={key} >
                                    <img className="profileHeader" src="https://wallpapercave.com/wp/5hMyv1Y.jpg"></img>
                                    <div className="profileDiv">
                                    
                                    <img className="avatarImg" src={val.avatar_urls.full}></img>
                                    <h1>{val.name}</h1>
                                    <div>
                                        {psnProfile(val)}
                                    
                                        <h4>Konzoljai:</h4>
                                        {val.xprofile.groups[0].fields[2].value.unserialized.map((vali: any) => {
                                            return (
                                                <p className="consoletag">{vali}</p>
                                            )
                                        })}
                                    <center><hr></hr></center>
                                    </div>
                                    <div className="twocolumn">
                        
                                    {news()}
                                    {articles()}

                                    </div>
                                    </div>
                                </div>
                            )
                        })}
        </div>
    );
}

export default Profile;
