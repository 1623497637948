import React, { useEffect, useState } from "react";
import NewsTopLoop from "../News/NewsLoop";
import axios from 'axios';
import LoadingSpinner from '../LoadingSpinner';
import ComingSoon from "./ComingSoon";
import CarouselBlock from "./Carousel";
import MidBlockNews from "./MidBlockNews";
import MidBlockArticles from "./MidBlockArticles";
import RecentComments from "./RecentComments";
import Youtube from "./Youtube";
import NewsByTag from "./NewsByTag";
import applelogo from "../../img/apple.png"
import androidLogo from "../../img/android.png"
import playstationlogo from "../../img/playstation.png"



function Home() {
  var [Page, updatePages] = useState<any[]>([])
  document.title = "PlayStation.Community"
  const [isLoading, setIsLoading] = useState(false);
  var [marqueeContent, updateMarque] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
    setIsLoading(true)
    var userId = ""
        if(localStorage.getItem("userId") != null){
            userId = "&user=" + localStorage.getItem("userId")
        }
    axios
      .get("https://playstationcommunity.hu/wp-json/kingweb/v1/id?type=post&limit=3&page=1" + userId)
      .then((res) => {
        updatePages(res.data)
      })
      axios
      .get("https://playstationcommunity.hu/wp-json/wp/v2/fenyhir")
      .then((res) => {
        
        updateMarque(res.data[0].content.rendered)
        setTimeout(() => {
          setIsLoading(false)
        }, 500);
      })
  }, [])

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    )
  } else {
    return (
      <div>
          <div className="marquee">
          <div dangerouslySetInnerHTML={{ __html: marqueeContent } } />
            
              </div>
          <div className='HomePageBlock'>
                <div className="nomob">
                  {Page.map((val, key) => {
                    return (
                      <div key={key} className='newsBlock blockHomePage newsBlock2home'>
                        {NewsTopLoop(val, false)}
                      </div>
                    )
                  })}
                </div>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4858592308850935"
     crossOrigin="anonymous"></script>
<ins className="adsbygoogle"
style={{display: "block"}}
     data-ad-client="ca-pub-4858592308850935"
     data-ad-slot="6656378469"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>
                    <a href="https://discord.com/invite/YPHkDBj" target="_blank"><div className="discord">
                      <img className="disrcordImage" src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6cc3c481a15a141738_icon_clyde_white_RGB.png"></img>
                    <p className="discordTitle">Csatlakozz Discord szerverünkhöz!</p>
                    </div>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=psc.hu&hl=en_US&gl=US&pli=1" target="_blank"><div className="discord android">
                      <img className="disrcordImage" src={androidLogo}></img>
                    <p className="discordTitle">Töltsd le Android alkalmazásunk!</p>
                    </div>
                    </a>
                    <a href="https://apps.apple.com/hu/app/psc-hu/id1553670517" target="_blank"><div className="discord ios">
                      <img className="disrcordImage" src={applelogo}></img>
                    <p className="discordTitle">Töltsd le iOS alkalmazásunk!</p>
                    </div>
                    </a>
                    <a href="https://www.facebook.com/groups/127411200800652?locale=hu_HU" target="_blank"><div className="pshungary">
                      <img className="disrcordImage psImage" src={playstationlogo}></img>
                    <p className="psTitle">PlayStation Hungary - az ország legnagyobb PS csoportja</p>
                    </div>
                    </a>
                <div className="articleBigDiv">
                  <CarouselBlock></CarouselBlock>
                </div>
                <div className="youtubeAndArt">
                  <div className="youtube">
                    <Youtube></Youtube>
                  </div>
                  <div className="articlebyTag">
                    <NewsByTag></NewsByTag>
                  </div>
                </div>
                <div className="homepageArticles">
            <div className="column1">
            <a href="/hirek"> <div className="newsTitleBox">Hírek</div></a>
              <MidBlockNews></MidBlockNews>
            </div>
            <div className="column2 nomob">
            <a href="/cikkek"><div className="newsTitleBox">Cikkek</div></a>
              <MidBlockArticles></MidBlockArticles>
            </div>
          
        </div>
        <div className="recentAndAd">

        
        <div className="recentCo">
        <div className="newsTitleBox">Legutóbbi hozzászólások</div>
        <RecentComments></RecentComments>
        </div>
        </div>
        
          </div>
          {/* <ComingSoon></ComingSoon> */}
          
      </div>
    )
  }
}

export default Home;