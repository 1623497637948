import React, { useState } from "react";

function NotFound() {
    var [selectedCat, changeSelected] = useState("hirek")
    return (
        <div className="notFound">
            <h2>Keresés</h2>
            <p>Válaszd ki, hogy milyen kategóriában, utána írd be, hogy mit szeretnél keresni!</p>
            <select value={selectedCat} onChange={e => changeSelected(e.target.value)} className="selectedCategory">
            <option value="hirek">Hírek</option>
            <option value="cikkek">Cikkek</option>
            <option value="forum">Fórum</option>
          </select>
            <input placeholder="Írd be mit szeretnél keresni" className="searchInput" type="text"></input><button className="searchButton">Keresés</button>
        </div>
    );
}

export default NotFound;
