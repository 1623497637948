import React, { useEffect, useState } from "react";
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClock, faComment, faGlasses, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";


function CarouselBlock() {
  var [Articles, updateArticles] = useState<any[]>([])
  var [isLoading, setIsLoading] = useState("true")

  useEffect(() => {
    setIsLoading("true")
    var userId = ""
        if(localStorage.getItem("userId") != null){
            userId = "&user=" + localStorage.getItem("userId")
        }
    axios
      .get("https://playstationcommunity.hu/wp-json/kingweb/v1/id?type=page&limit=8&page=1" + userId)
      .then((res) => {
        updateArticles(res.data)
        setIsLoading("false")
      })
    

  }, [])
  function watched(isWatched: Boolean): React.ReactNode {
    if(isWatched){
            return (<p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faCheckCircle} /></p>)
    }
    
}
  if(isLoading === "true"){
    return(<div></div>)
  } else {
    return (
      
      <div>
            <Carousel autoPlay infiniteLoop showThumbs={false} transitionTime={400} interval={7000}>

            <Link to={{ pathname: '/' + Articles[0].slug }}>
              <div>
                <img className="bigArticleImage imageBig" src={Articles[0].jetpack_featured_media_url} />
                <div className='additionalText carouselAd'>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faUser} />{Articles[0].author_meta.display_name}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faClock} />{Articles[0].formatted_date}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faComment} />{Articles[0].comment_count}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faGlasses} />{Articles[0].readinminute} perc</p>
                  {watched(Articles[0].isWatched)}
                </div>
                <div className="legend">{Articles[0].title.rendered}</div>
               
              </div>
              </Link>
               
              <Link to={{ pathname: '/' + Articles[1].slug }}>
              <div>
                
                <img className="bigArticleImage imageBig" src={Articles[1].jetpack_featured_media_url} />
                <div className='additionalText carouselAd'>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faUser} />{Articles[1].author_meta.display_name}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faClock} />{Articles[1].formatted_date}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faComment} />{Articles[1].comment_count}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faGlasses} />{Articles[1].readinminute} perc</p>
                  {watched(Articles[1].isWatched)}
                </div>
                <div className="legend">{Articles[1].title.rendered}</div>
                
              </div>
              </Link>
              
              <Link to={{ pathname: '/' + Articles[2].slug }}>
              <div>
              
                <img className="bigArticleImage imageBig" src={Articles[2].jetpack_featured_media_url} />
                <div className='additionalText carouselAd'>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faUser} />{Articles[2].author_meta.display_name}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faClock} />{Articles[2].formatted_date}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faComment} />{Articles[2].comment_count}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faGlasses} />{Articles[2].readinminute} perc</p>
                  {watched(Articles[2].isWatched)}
                </div>
                <div className="legend">{Articles[2].title.rendered}</div>
                
              </div>
              </Link>
              
              <Link to={{ pathname: '/' + Articles[3].slug }}>
              <div>
              
                <img className="bigArticleImage imageBig" src={Articles[3].jetpack_featured_media_url} />
                <div className='additionalText carouselAd'>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faUser} />{Articles[3].author_meta.display_name}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faClock} />{Articles[3].formatted_date}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faComment} />{Articles[3].comment_count}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faGlasses} />{Articles[3].readinminute} perc</p>
                  {watched(Articles[3].isWatched)}
                </div>
                <div className="legend">{Articles[3].title.rendered}</div>
                
              </div>
              </Link>
             
              <Link to={{ pathname: '/' + Articles[4].slug }}>
              <div>
              
                <img className="bigArticleImage imageBig" src={Articles[4].jetpack_featured_media_url} />
                <div className='additionalText carouselAd'>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faUser} />{Articles[4].author_meta.display_name}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faClock} />{Articles[4].formatted_date}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faComment} />{Articles[4].comment_count}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faGlasses} />{Articles[4].readinminute} perc</p>
                  {watched(Articles[4].isWatched)}
                </div>
                <div className="legend">{Articles[4].title.rendered}</div>
                
              </div>
              </Link>
              
              
              <Link to={{ pathname: '/' + Articles[5].slug }}>
              <div>
              
                <img className="bigArticleImage imageBig" src={Articles[5].jetpack_featured_media_url} />
                <div className='additionalText carouselAd'>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faUser} />{Articles[5].author_meta.display_name}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faClock} />{Articles[5].formatted_date}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faComment} />{Articles[5].comment_count}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faGlasses} />{Articles[5].readinminute} perc</p>
                  {watched(Articles[5].isWatched)}
                </div>
                <div className="legend">{Articles[5].title.rendered}</div>
                
              </div>
              </Link>
              
              <Link to={{ pathname: '/' + Articles[6].slug }}>
              <div>
              
                <img className="bigArticleImage imageBig" src={Articles[6].jetpack_featured_media_url} />
                <div className='additionalText carouselAd'>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faUser} />{Articles[6].author_meta.display_name}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faClock} />{Articles[6].formatted_date}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faComment} />{Articles[6].comment_count}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faGlasses} />{Articles[6].readinminute} perc</p>
                  {watched(Articles[6].isWatched)}
                </div>
                <div className="legend">{Articles[6].title.rendered}</div>
                
              </div>
              </Link>
              
              <Link to={{ pathname: '/' + Articles[7].slug }}>
              <div>
              
                <img className="bigArticleImage imageBig" src={Articles[7].jetpack_featured_media_url} />
                <div className='additionalText carouselAd'>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faUser} />{Articles[7].author_meta.display_name}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faClock} />{Articles[7].formatted_date}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faComment} />{Articles[7].comment_count}</p>
                  <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faGlasses} />{Articles[7].readinminute} perc</p>
                  {watched(Articles[7].isWatched)}
                </div>
                <div className="legend">{Articles[7].title.rendered}</div>
               
              </div>
              </Link>
              
            </Carousel>
      </div>
    )
  }
    
}

export default CarouselBlock;
