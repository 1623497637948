import React, { useState, useEffect } from 'react';
import NewsTopLoop from "../News/NewsTopLoop";
import axios from 'axios';
import LoadingSpinner from '../LoadingSpinner';
import { useParams } from 'react-router-dom';

export default function TagPage() {

    document.title = "PlayStationCommunity.hu"
    let { slug, tag } = useParams();
    var [Page, updatePages] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false);
    var [pagination, setPagination] = useState(1);

    const handlePagination = (e: string) => {
        if (e === "back") {
            if (pagination !== 1) {
                setPagination(pagination - 1)
            }
        }
        if (e === "next") {
            setPagination(pagination + 1)
        }
    }
    useEffect(() => {
        setIsLoading(true)
            axios
            .get("https://playstationcommunity.hu/wp-json/kingweb/v1/id?type=post&limit=30&page=" + pagination + "&tag=" + slug)
            .then((res) => {
                updatePages(res.data)
                setIsLoading(false)
            })
        

    }, [pagination])

    if (isLoading) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        )
    } else {
        return (
            <div>
                <div className='block'>
                    <center><h1>{tag}</h1></center>
                    
                    <div>
                        <div className='paginationDiv'><button className='paginationButton' onClick={e => handlePagination("back")}>&lt;</button>{pagination}. oldal<button style={Page.length > 19 ? {display: "inline-block"} : {display: "none"}} className='paginationButton' onClick={e => handlePagination("next")}>&gt;</button></div>
                        {Page.map((val, key) => {
                            return (
                                <div key={key} className='newsBlock'>
                                    {NewsTopLoop(val, false)}
                                </div>
                            )
                        })}
                        <div className='paginationDiv'><button className='paginationButton' onClick={e => handlePagination("back")}>&lt;</button>{pagination}. oldal<button style={Page.length > 19 ? {display: "inline-block"} : {display: "none"}} className='paginationButton' onClick={e => handlePagination("next")}>&gt;</button></div>
                    </div>


                </div>
            </div>
        )
    }

}