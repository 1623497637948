import React from 'react';
import '../../css/News.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { faUser, faClock, faComment, faGlasses, faCheckCircle, faHeart } from '@fortawesome/free-solid-svg-icons';

export default function NewsLoop(news: any) {
    const cimke = (): string =>  {
        var titleof = ""
        if(news.fields.cimke_oldalon_lathato !== undefined) {
            titleof = news.fields.cimke_oldalon_lathato.split(":")[0]
        }
        return titleof
    }
    const cimkeColor = (): string =>  {
        var titleof1 = ""
        if(news.fields.cimke_oldalon_lathato !== undefined) {
            titleof1 = news.fields.cimke_oldalon_lathato.split(":")[1]
        } else {
            titleof1 = "rgba(0,0,0,0)"
        }
        return titleof1
    }
    function watched(): React.ReactNode {
        if(news.isWatched){
                return (<p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faCheckCircle} /></p>)
        }
        
    }
    function faved(): React.ReactNode {
        if(news.isFav){
                return (<p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faHeart} /></p>)
        }
        
    }
    return (
        <Link to={{ pathname: '/' + news.slug }}>
            <div className='newsBlock2'>
                <div className="ribbon-2" style={{backgroundColor: cimkeColor()}}>{cimke()}</div>
                
                <div className="topof">
                    <div className='newsTopInfos'>
                        <div className='newsTopTitle'><h5>{news.title.rendered}</h5></div>
                        <div className='subline'><p>{news.excerpt.rendered}</p></div>
                        <div className='additionalText'>
                            <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faUser} />{news.author_meta.display_name}</p>
                            <p className='textAuthor'><FontAwesomeIcon className='faIcon' icon={faClock} />{news.formatted_date}</p>
                            <p className='textAuthor' style={{display: news.comment_count > 0 ? 'block' : 'none'}}><FontAwesomeIcon className='faIcon' icon={faComment} />{news.comment_count}</p>
                            {watched()}
                        {faved()}
                            </div>
                    </div>
                </div>
                <div className="container ">
                    <div className="box"> <img alt={news.title.rendered} src={news.jetpack_featured_media_url} className='newsImage'></img></div>
                    <div className="box overlay">

                    </div>
                </div>
            </div>
        </Link>
    );
}