import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon
  } from "react-share";

export default function Sidebar(article: any) {

    var [Page, updatePages] = useState<any[]>([])
    var [Article, updateArticle] = useState<any[]>([])
    var [ArticleTitle, updateArticleTitle] = useState("Friss cikkek")
    useEffect(() => {
        axios
            .get("https://playstationcommunity.hu/wp-json/kingweb/v1/id?type=post&limit=3&page=1")
            .then((res) => {
                updatePages(res.data)
            }).then(() => {
                if(article.isArticle === "true"){
                    axios.get("https://playstationcommunity.hu/wp-json/kingweb/v1/authorpage?limit=3&author=" + article.article.author_meta.author_id)
                    .then((res) => {
                        updateArticle(res.data)
                        updateArticleTitle(article.article.author_meta.display_name + " cikkei")
                    })
                } else {
                    axios
                            .get("https://playstationcommunity.hu/wp-json/kingweb/v1/cikkek?type=page&limit=3&page=1")
                            .then((res) => {
                                updateArticle(res.data)
                                updateArticleTitle("Friss cikkek")
                            })
                }
                
                
            }
            )

    }, [])
        return (
                    <div className='stickySidebar'>
                        <h6>Megosztás</h6>
                        <FacebookShareButton
                                url={article.article.link}
                                quote={article.article.title.rendered}
                            >
                                <FacebookIcon size={30}/>
                        </FacebookShareButton>
                        <FacebookMessengerShareButton
                            url={article.article.link}
                            appId="521270401588372"
                        >
                            <FacebookMessengerIcon size={30}/>
                        </FacebookMessengerShareButton>
                        <TwitterShareButton
                                url={article.article.link}
                                title={article.article.title.rendered}
                            >
                                <TwitterIcon size={30} />
                            </TwitterShareButton>
                            <WhatsappShareButton
                                    url={article.article.link}
                                    title={article.article.title.rendered}
                                    separator=":: "
                                >
                                <WhatsappIcon size={30}/>
                            </WhatsappShareButton>
                            <EmailShareButton
                                    url={article.article.link}
                                    subject={article.article.title.rendered}
                                    body="body"
                                >
                                    <EmailIcon size={30}/>
                                </EmailShareButton>
                                <center><hr></hr></center>
                                <a href="https://discord.com/invite/YPHkDBj" target="_blank"><div className="discordin">
                      <img className="disrcordImagein" src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6cc3c481a15a141738_icon_clyde_white_RGB.png"></img>
                    <p className="discordTitlein">Csatlakozz Discord szerverünkhöz!</p>
                    </div></a>
                        <center><h4 className='sidebarTitle'>Friss hírek</h4></center>
                        
                        {Page.map((val, key) => {
                            return (
                                <Link to={{ pathname: '/hirek/komment/' + val.slug }}>
                                <div key={key} className='sidebarArticle'>
                                    <img src={val.jetpack_featured_media_url}></img>
                                    <h4>{val.title.rendered}</h4>
                                    <p>{val.excerpt.rendered}</p>
                                </div>
                                </Link>
                            )
                        })}
                        <center><h4 className='sidebarTitle'>{ArticleTitle}</h4></center>
                        
                        {Article.map((val, key) => {
                            return (
                                <Link to={{ pathname: '/' + val.slug }}>
                                <div key={key} className='sidebarArticle'>
                                    <img src={val.jetpack_featured_media_url}></img>
                                    <h4>{val.title.rendered}</h4>
                                    <p>{val.excerpt.rendered}</p>
                                </div>
                                </Link>
                            )
                        })}
                    </div>
        )

}