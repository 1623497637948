import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import '../../css/News.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClock, faComment, faGlasses, faHeart, faHeartBroken } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import LoadingSpinner from '../LoadingSpinner';
import Comments from '../Comment';
import Sidebar from '../Sidebar/Sidebar';
import positive from '../../img/positive.png'
import negative from '../../img/negative.png'

interface NewsContentProps {
    setArticleData: (data: any) => void; // Adjust the type accordingly
  }

export default function ArticleContent({ setArticleData }: NewsContentProps) {

    var [Page, updatePages] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false);
    var [enlargedImage, setEnlarge] = useState("");
    var [isVisibles, setVisible] = useState(false)
    const [editUrl, setEditUrl] = useState("")
    let { id } = useParams();
    const handleClickScroll = () => {
        const element = document.getElementById('comments');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
      
    useEffect(() => {
        window.scrollTo(0, 0)
        setIsLoading(true)
        var userId = ""
        if(localStorage.getItem("userId") != null){
            userId = "&user=" + localStorage.getItem("userId")
        }
        axios
            .get("https://playstationcommunity.hu/wp-json/kingweb/v1/id?title=" + id + "&type=page" + userId)
            .then((res) => {
                updatePages(res.data)
                document.title = res.data[0].title.rendered + " - PlayStationCommunity.hu"
                console.log(res.data[0])
                setIsLoading(false)
                setEditUrl("https://playstationcommunity.hu/wp-admin/post.php?post=" + res.data[0].id + "&action=edit")
                setArticleData(res.data[0])
                if(localStorage.getItem('isUserLoggedIn') == "true"){
                   const data = [
                         res.data[0].id
                   ]; 
                axios.post("https://playstationcommunity.hu/wp-json/kingweb/v1/watched/" + localStorage.getItem("userId"), data)
                }
                
            }).catch(() => {
                window.location.replace('/notfound')
            })
document.querySelectorAll('strong').forEach(link => {
    link.addEventListener('click', (e) => {
      window.alert("s")
    });
  });
    }, [id, setArticleData])
    function editButton(): React.ReactNode {
        if(localStorage.getItem('isUserLoggedIn') == "true"){
            if(localStorage.getItem('isAdmin') == "true"){
                return (<a className="favbutton" href={editUrl}>Szerkesztés</a>)
            }
        }
        
    }
    
    const platforms = (e: [any]) => {
        var platis: JSX.Element[] = []
        if(e !== undefined) {
            e.forEach(element => {
                platis.push(<p className="platformtag">{element}</p>)
        })
        }
        
        return (
            <div className='platforms'>
            {platis}
            </div>
        )
    }
    const review = (fields: any, background: string) => {
        var flieds: JSX.Element[] = []
        var martinBeleszol: JSX.Element[] = []

        if(fields["indie-e"] === "nemindie") {
            if(fields["gyengesegei"] !== "" && fields["gyengesegei"] !== undefined){
            var gyengesegek = fields["gyengesegei"].split(";")
            var gyengesegekElements: JSX.Element[] = []
            gyengesegek.forEach((element: any) => {
                gyengesegekElements.push(<p className='gyengesegek'><img className="bulletIcon" src={negative}></img>{element}</p>)
            });

            var erossegek = fields["erossegei"].split(";")
            var erossegekElements: JSX.Element[] = []
            erossegek.forEach((element: any) => {
                erossegekElements.push(<p className='erossegek'><img className="bulletIcon" src={positive}></img>{element}</p>)
            });

            flieds.push(<div className='firstDiv'><p>Erősségei:</p>{erossegekElements}</div>)
            flieds.push(<div className='secondDiv'><p>Gyengeségei:</p>{gyengesegekElements}</div>)
            flieds.push(
                        <div className='thirdDiv'>
                        <h3>{fields["pontszam"]}/10</h3>
                        <p>Látványosság: <b>{fields["latvanyossag"]}</b></p>
                        <p>Játszhatóság: <b>{fields["jatszhatosag"]}</b></p>
                        <p>Szavatosság: <b>{fields["szavatossag"]}</b></p>
                        <p>Zene/Hang: <b>{fields["zenehang"]}</b></p>
                        <p>Hangulat: <b>{fields["hangulat"]}</b></p>
                        </div>

                        )
                    }
        }
        if(fields["martinbeleszol"] !== ""){
            martinBeleszol.push(<blockquote>{fields["martinbeleszol"]}</blockquote>)
        }
        if(fields["indie-e"] === "nemindie") {
            if(fields["gyengesegei"] !== "" && fields["gyengesegei"] !== undefined){
            return(
                <div className='reviewAll'>
                    <img
                    className="image-bg-review"
                    src={background}
                    alt=""
                ></img>
                <div className='reviewBg'>
                <div className='review'>
                    {flieds}
                </div>
                {martinBeleszol}
                </div>
                </div>
            )
            } else {
                return(
                    <div></div>
                )
            }
        } else if (fields["ertekeloindie"] === "igen") {
            var csillagozas: JSX.Element[] = []
            switch (fields["csillagozas"]) {
                case "5 csillag":
                    csillagozas.push(<div className='indieReview' style={{backgroundImage: background}}><p className='starRating'>★★★★★</p><h2 className='starTitle'>Színarany</h2></div>)
                    break;
                case "4 csillag":
                    csillagozas.push(<div className='indieReview'><p className='starRating'>★★★★☆</p><h2 className='starTitle'>Remek</h2></div>)
                    break;
                case "3 csillag":
                    csillagozas.push(<div className='indieReview' style={{backgroundImage: background}}><p className='starRating'>★★★☆☆</p><h2 className='starTitle'>Középszerű</h2></div>)
                    break;
                case "2 csillag":
                    csillagozas.push(<div className='indieReview' style={{backgroundImage: background}}><p className='starRating'>★★☆☆☆</p><h2 className='starTitle'>Csak óvatosan</h2></div>)
                    break;
                case "1 csillag":
                    csillagozas.push(<div className='indieReview' style={{backgroundImage: background}}><p className='starRating'>★☆☆☆☆</p><h2 className='starTitle'>Inkább ne</h2></div>)
                    break;
                default:
                    break;
            }
            return(
                <div className='reviewAll'>
                                    <img
                    className="image-bg"
                    src={background}
                    alt=""
                ></img>
                    {csillagozas}
                    {martinBeleszol}
                </div>
            )
        } else {
            return(
                <div></div>
            )
        }
        
    }
    const fields = (fejleszto: string, kiado: string, megjelenes: string, price: string) => {
        var flieds: JSX.Element[] = []

        if(fejleszto !== "" && fejleszto !== undefined) {
            flieds.push(<p className="platformtag">Fejlesztő: {fejleszto}</p>)
        }
        if(kiado !== "" && kiado !== undefined) {
            flieds.push(<p className="platformtag">Kiadó: {kiado}</p>)
        }
        if(megjelenes !== "" && megjelenes !== undefined) {
            flieds.push(<p className="platformtag">Megjelenés: {megjelenes}</p>)
        }
        if(price !== "" && price !== undefined) {
            flieds.push(<p className="platformtag">Ár: {price}</p>)
        }

        return(
            <div>
                {flieds}
            </div>
        )
    }
    const handleGallery = (e: any) =>{
        if (e.target.tagName === "IMG") {
            setEnlarge(e.target.src)
            e.preventDefault()
            setVisible(true)
            document.body.style.overflow = 'hidden';
        }
    }
    const closeGallery = () => {
        setVisible(false)
        setEnlarge("")
        document.body.style.overflow = 'auto';
    }

    if (isLoading) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        )
    } else {
        function Tags(tags: any): React.ReactNode {
            if(tags.length > 0){
                return(
                    tags.map((val:any) => {
                        var link = "/tag/" + val.slug + "/" + val.name
                        return(
                            <Link to={link}>
                            <div className='tag'>{val.name}</div>
                            </Link>
                        )
                    })
                )
            }
            
        }
        return (
            <div>
                <div className='blockContent'>
                    <div>
                        {Page.map((val, key) => {
                            
                            function handleFav() {
                                const data = [val.id]; 
                                     axios.post("https://playstationcommunity.hu/wp-json/kingweb/v1/fav/" + localStorage.getItem("userId"), data).then(response => {
                                        window.location.reload();
                                    })
                                     
                                
                            }
                            function watchlistButton(): React.ReactNode {
        
                                if(localStorage.getItem('isUserLoggedIn') == "true"){
                                    if(!val.isFav){
                                        return (<p onClick={handleFav} className="favbutton"><FontAwesomeIcon className='faIcon' icon={faHeart} /> Kedvencekhez adom</p>)
                                    } else {
                                        return (<p onClick={handleFav} className="favbutton activeFav"><FontAwesomeIcon className='faIcon' icon={faHeartBroken} /> Eltávolítás a kedvencekből</p>)
                                    }
                                }
                            }
                            return (
                                <div>
                                    <div id="galleryModal" className="modal" style={{display: isVisibles ? 'block' : 'none'}}>
                                        <span className="close" onClick={closeGallery}>&times;</span>
                                        <img id="modal-img" className="modal-content"  src={enlargedImage} />
                                            
                                        
                                        <div id="caption"></div>
                                    </div>
                                    <div id="container">
                                        <div id="navi">
                                            <img alt={val.title.rendered} className='content-image' src={val.jetpack_featured_media_url}></img>
                                        </div>
                                        <div id="infoi">
                                        <div className='additionalText additionalTextContent'>
                                                <Link to={{ pathname: '/tagok/' + parseInt(val.author_meta.author_id) }}>
                                                <div>
                                                    <img className="authorAvatar" alt={val.author_meta.display_name} src={val.author_meta.avatar_url}/>
                                                    <p>{val.author_meta.display_name}</p></div>
                                                </Link>
                                                <p className='timein'><FontAwesomeIcon className='faIcon' icon={faClock} />{val.formatted_date}</p>
                                                <p className='timein'><FontAwesomeIcon className='faIcon' icon={faGlasses} />{val.readinminute} perc</p>
                                                <p onClick={handleClickScroll} className='commentsNews'><FontAwesomeIcon className='faIcon' icon={faComment} />{val.comment_count}</p>
                                            </div><center><hr></hr></center>
                                            <h1>{val.title.rendered}</h1>
                                            {platforms(val.fields.platform)}
                                            {fields(val.fields.fejleszto, val.fields.kiado, val.fields.megjelenes, val.fields.ar)}
                                        </div>
                                    </div>

                                    <div className='contentBody'>
                                        <div className='divone'> 
                                        {watchlistButton()}
                                        {editButton()}
                                            {screen.width > 900 ? <div id='playstationcomm_cikk_pc_640x360_1'></div> : <div id='playstationcomm_cikk_mobil_2'></div>}
                                        <div className='contentone' dangerouslySetInnerHTML={{ __html: val.content }} onClick={e => handleGallery(e)}/>
                                        
                                        </div>
                                        <div className='divtwo'> <Sidebar article={val} isArticle={"true"}/></div>
                                    </div>

                                    <div className='tags'>Cimkék: {Tags(val.tags)}</div>
                                    {review(val.fields, val.jetpack_featured_media_url)}
                                    <Comments id={val.id} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

function jwt_decode(arg0: string): any {
    throw new Error('Function not implemented.');
}
