import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";


function MidBlockNews() {
  var [Comments, updateComments] = useState<any[]>([])

  useEffect(() => {
    axios
      .get("https://playstationcommunity.hu/wp-json/wp/v2/comments")
      .then((res) => {
        updateComments(res.data)
      })
  }, [])

    return (
      <div>
                    {Comments.map((val, key) => {
                        return (
                            <Link to={val.link.split("https://playstationcommunity.hu").pop().replace("#", "#")}>
                                <div className="recentCommentDiv">
                                    <div className="recentCommentAuthor"> <img className="recentCommentsImage" src={val.author_avatar_urls["96"]}></img></div>
                                    <div className="recentCommentsContent"> 
                                    <b><div dangerouslySetInnerHTML={{ __html: val.content.rendered.split("</p>")[0].slice(0, 100) + "..." } } /></b>
                                    <p className="recentDate"><b>{val.postTitle.replace("&#8211;", " - ")}</b> - {val.author_name} - {val.date.replace("T", " ")}</p>
                                    </div>
                                </div>
                            </Link>
                            )
                        })}
      </div>
    )
}

export default MidBlockNews;