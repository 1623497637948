import React, { useEffect, useState} from 'react';
import axios from 'axios';
import { Puff } from 'react-loading-icons';

import Swal from 'sweetalert2';
import { GoogleLogin } from '@react-oauth/google';


export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [email, setEmail] = useState("");
    const [displayName, setDisplay] = useState("");
    const [isLoading, setLoading] = useState(false);


    document.title = "Regisztráció - PlayStationCommunity.hu"

    useEffect(() => {
      window.scrollTo(0, 0)
  }, [])
    axios.interceptors.request.use(
        config => {

          const token = localStorage.getItem('token');
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );
      const handlePass = (e: string) => {

      }
      const handleGoogleLoginSuccess = (response: any) => {
        // Send the response object to BuddyPress API for registration
        // using an HTTP request (e.g., axios, fetch, etc.)
        console.log(response);
      };
    
      const handleGoogleLoginFailure = (error: any) => {
        console.error(error);
      };
    const handleLogin = () => {
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        setLoading(true);
        if(password.length <= 6){
            setLoading(false)
            return Swal.fire({
                
                icon: 'error',
                title: 'Mission Failed',
                text: 'Jelszó minimum 6 karakter hosszú kell legyen!',
                showConfirmButton: true
              })
        } else if (password != passwordConfirm){
            setLoading(false)
            return Swal.fire({
                icon: 'error',
                title: 'Mission Failed',
                text: 'Két jelszó nem egyezik!',
                showConfirmButton: true
              })
        } else if(!regex.test(email)){
            setLoading(false)
            return Swal.fire({
                icon: 'error',
                title: 'Mission Failed',
                text: 'Email cím nem megfelelő formátum!',
                showConfirmButton: true
              })
        } else if (displayName == ""){
            setLoading(false)
            return Swal.fire({
                icon: 'error',
                title: 'Mission Failed',
                text: 'Írj be egy megjelenítendő nevet!',
                showConfirmButton: true
              })
        } else {
            axios
            .post("https://playstationcommunity.hu/wp-json/buddypress/v1/signup?user_name="+displayName+"&password="+password+"&user_login="+username+"&user_email="+email)
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Mission Accomplished',
                    text: 'Sikeres regisztráció, visszaigazoló e-mail elküldve a megadott e-mail címre!',
                    showConfirmButton: true
                  }).then((result) => {
                    if(result.isConfirmed){
                        window.location.replace('/')
                    }
                  })
            })
            .catch((err) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Mission Failed',
                    text: err.response.data.message,
                    showConfirmButton: true
                  })
            }).finally(() => {
                setLoading(false)
            });
        }
        
    }
    function RegisterButton() {
        if (isLoading) {
          return <Puff stroke="#004f64" strokeOpacity={.125} />;
        }
        return <button type='button' onClick={handleLogin} className="searchButton">Regisztráció</button>;
      }
        return (
            <div className='loginScreen'>
                <h2>Regisztráció</h2>
                <form>
                    <input 
                    type="text" 
                    placeholder='Felhasználónév' 
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="searchInput loginInput" required>
                    </input>
                    <br></br>
                    <input 
                    type="text" 
                    placeholder='Megjelenítendő név' 
                    value={displayName}
                    onChange={(e) => setDisplay(e.target.value)}
                    className="searchInput loginInput" required>
                    </input>
                    <br></br>
                    <input 
                    type="text" 
                    placeholder='E-mail cím' 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="searchInput loginInput" required>
                    </input>
                    <br></br>
                    <input 
                    type="password" 
                    placeholder='Jelszó' 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="searchInput loginInput" required></input>
                    <br></br>
                    <input 
                    type="password" 
                    placeholder='Jelszó megerősítése' 
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    className="searchInput loginInput" required></input>
<br></br>
{<RegisterButton/>}
                </form>
                
      {/* <GoogleLogin
  onSuccess={credentialResponse => {
    console.log(credentialResponse.credential);
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/> */}
            </div>
        )

}