import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";


function TagsOf() {
  var [Tags, updateTags] = useState<any[]>([])

  useEffect(() => {
    axios
      .get("https://playstationcommunity.hu/wp-json/wp/v2/tags?orderby=count&order=desc&per_page=60")
      .then((res) => {
        updateTags(res.data)
      })
  }, [])
    if(Tags.length > 0){
      return (
        <div></div>
      )
    } else {
      return (
        <div className="HomePageBlock tagsDiv">
              <h3>Cimkék</h3>
                      {Tags.map((val, key) => {
                          return (
                              <Link to={{ pathname: val.link.split("https://playstationcommunity.hu").pop() + val.name.replace('#', '') }}>
                                  <div key={key}>
                                      {val.name}
                                  </div>
                              </Link>
                              )
                          })}
              
        </div>
      )
    }
    
}

export default TagsOf;