import React from "react";
import "../css/spinner.css";
import loadingImage from "../img/loading/loading_0.gif"
import loadingImage1 from "../img/loading/loading_1.gif"

export default function LoadingSpinner() {
    var loadingImages = [loadingImage, loadingImage1]

    return (
        <div className="spinner-container">
            <center><div><img alt="Loading" src={random_item(loadingImages)}></img>
            </div></center>
        </div>
    );
}
function random_item(items: any) {

    return items[Math.floor(Math.random() * items.length)];

}