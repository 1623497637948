import React, { useEffect, useState} from 'react';
import axios from 'axios';
import { Puff } from 'react-loading-icons';

import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';


export default function Login() {
    let { activation } = useParams();
    const [message, setMessage] = useState("");


    document.title = "Aktiválás - PlayStationCommunity.hu"

    useEffect(() => {
      window.scrollTo(0, 0)

      handleLogin()
  }, [])
    const handleLogin = () => {
        
            axios
            .post("https://playstationcommunity.hu/wp-json/buddypress/v1/signup/activate/"+activation)
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Mission Accomplished',
                    text: 'Sikeres aktiválás, továbbirányítunk a bejelentkezési oldalra!',
                    showConfirmButton: true
                  }).then((result) => {
                        if(result.isConfirmed){
                            window.location.replace('/bejelentkezes')
                        }
                  })
            })
            .catch((err) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Mission Failed',
                    text: err.response.data.message,
                    showConfirmButton: true
                  })
            }).finally(() => {
            });
        
        
    }
        return (
            <div className='loginScreen'>
                <h2>Aktiválás</h2>
                
      
            </div>
        )

}