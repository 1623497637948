import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { Link, useActionData } from 'react-router-dom';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type Props = {
    id: number
}
  
interface Post {
    id: number;
    post: number;
    parent: number;
    author: number;
    author_name: string;
  }
  
  function getChildren(posts: Post[], main: number): number[] {
    const children: number[] = [];
    for (const post of posts) {
      if (post.parent === main) {
        children.push(post.id);
        children.push(...getChildren(posts, post.id));
      }
    }
    return children;
  }
  
  function createThreadArray(posts: Post[]): { main: number; children: number[] }[] {
    const threads: { main: number; children: number[] }[] = [];
    const mainPosts: Set<number> = new Set();
    for (const post of posts) {
      if (post.parent === 0) {
        mainPosts.add(post.id);
      }
    }
    for (const mainPost of mainPosts) {
      threads.push({ main: mainPost, children: getChildren(posts, mainPost) });
    }
    return threads;
  }
  
  
  
export default function Comments({ id }: Props) {
    var [Comments, updateComments] = useState<any[]>([])
    var [threadComments, updateThreads] = useState<any[]>([])
    var [commentContent, updateContent] = useState("")
    var [parent, updateParent] = useState(0)
    var [commentTitle, updateCommentTitle] = useState("")
    var [isReplyLoading, updateIsReplyLoading] = useState(false)
    var [commentReplyContent, updateCommentReplyTitle] = useState("")

    useEffect(() => {
        getComments()
    }, [])

    const handleReply = (e: any, author: string, replyContent: string) => {
        updateParent(e)
        updateCommentTitle("Válasz " + author + " hozzászólására")
        updateCommentReplyTitle(replyContent)
        handleClickScroll()
    }
    const handleClickScroll = () => {
        const element = document.getElementById('commentReply');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
    const commentLayout = ( content : any, isReply: boolean) => {
        if(isReply){
            return (
                <div className='commentDiv reply' id={content.id}>
                <div className='commentMeta'>
                <Link to={{ pathname: '/tagok/' + parseInt(content.author) }}><img alt={content.author_name} className='commentAvatar' src={content.author_avatar_urls["96"]}></img></Link>
                </div>
                <div className='commentContent' >
                <p className='prevAuthor'>Válasz {Comments.filter(e => e.id === content.parent)[0].author_name} üzenetére:</p>
                <div className='previousComment' dangerouslySetInnerHTML={{ __html: Comments.filter(e => e.id === content.parent)[0].content.rendered.slice(0, 100) + "..." }} />
                <div dangerouslySetInnerHTML={{ __html: content.content.rendered }} />
                <p className='authorMeta'><Link to={{ pathname: '/tagok/' + parseInt(content.author) }}>{content.author_name}</Link> | {content.date.replace("T", " - ")}</p>
                <div className='replycontent'>
                <a className='replyto' onClick={e => handleReply(content.id, content.author_name, content.content.rendered)}>Válasz</a>
                </div>
                </div>
                </div>
            )
        } else {
            return (
                <div className='commentDiv' id={content.id}>
                <div className='commentMeta'>
                <Link to={{ pathname: '/tagok/' + parseInt(content.author) }}><img alt={content.author_name} className='commentAvatar' src={content.author_avatar_urls["96"]}></img></Link>
                </div>
                <div className='commentContent' >
                <div dangerouslySetInnerHTML={{ __html: content.content.rendered }} />
                <p className='authorMeta'><Link to={{ pathname: '/tagok/' + parseInt(content.author) }}>{content.author_name}</Link> | {content.date.replace("T", " - ")}</p>
                <div className='replycontent'>
                <a className='replyto' onClick={e => handleReply(content.id, content.author_name, content.content.rendered)}>Válasz</a>
                </div>
                </div>
                </div>
            )
        }
        
    }
    const postComment = () => {
        if(commentContent !== undefined){
            if(commentContent !== ""){
                updateIsReplyLoading(true)
                axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}
                axios
                .post("https://playstationcommunity.hu/wp-json/wp/v2/comments?post=" + id + "&parent=" + parent + "&content=" + commentContent)
                .then((res) => {
                    updateContent("")
                    updateCommentTitle("")
                    getComments()
                    updateIsReplyLoading(false)
                })
            }
        }
    }
    function getComments() {
        axios
        .get("https://playstationcommunity.hu/wp-json/wp/v2/comments?post=" + id + "&per_page=100")
        .then((res) => {
            const threads: any[] = createThreadArray(res.data);
            updateThreads(threads)
            updateComments(res.data)
        })
    } 
    function comments() {
        var layout: any = []
        threadComments.forEach((element) => {
            layout.push(commentLayout(Comments.filter(e => e.id === element.main)[0], false))
            if(element.children.length > 0){
                element.children.forEach((element: number) => {
                    layout.push(commentLayout(Comments.sort((a,b) => a.id > b.id ? 1:-1).filter(e => e.id === element)[0], true))
                })
            }
        })
        return layout
    } 

    function replyComment() {
        if(isReplyLoading){
            return(
                <div>
                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            )
        } else {
            if(localStorage.getItem('isUserLoggedIn') === "true"){
                return (
                    <div className='commentBlock' id='commentReply'>
                        <hr></hr>
                        <h2>Hozzászólás</h2>
                        <form>
                            <p>{commentTitle}</p>
                            <div className='previousComment' dangerouslySetInnerHTML={{ __html: commentReplyContent }} />
                             <ReactQuill theme="snow" value={commentContent} onChange={updateContent} /> 
                            <button type="button" onClick={postComment}>Elküld</button>
                        </form>
                        <hr></hr>
                    </div>
                )
            } else {
                return (
                    <div>
                    <center><hr></hr></center>
                        <p><Link to='/bejelentkezes'>Jelentkezz be</Link> és szólj hozzá!</p>
                        </div>
                )
            }
        }
        
        
    }
    function noComment() {
        if(localStorage.getItem('isUserLoggedIn') === "true"){
            return (
                <div>
                    <center><div id="comments" className='comments'>
                        <h2>Kommentek:</h2>
                        <center><hr></hr></center>
                        <p>Jelenleg még nincs hozzászólás, legyél te az első!</p>
                        {replyComment()}
                        <div>
                        </div>
                    </div>
                    </center>
                </div >
            )
        } else {
            return (
                <div>
                    <center><div id="comments" className='comments'>
                        <h2>Kommentek:</h2>
                        <center><hr></hr></center>
                        <p>Jelenleg még nincs hozzászólás, jelentkezz be és legyél te az első!</p>
                        <div>
                        </div>
                    </div>
                    </center>
                </div >
            )
        }
        
    }

    if(Comments.length > 0){
        return (
            <div>
                <center><div id="comments" className='comments'>
                    <h2>Kommentek:</h2>
                    {replyComment()}
                    {comments()}
                    
                    <div>
                    </div>
                </div>
                </center>
            </div >
        )
    } else {
        return (
            <div>
            {noComment()}
            </div>
        )
        
    }
    
}