import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import Home from "./Components/tsx/Home";
import NotFound from './Components/tsx/NotFound';
import News from './Components/tsx/News/News';
import NewsContent from './Components/tsx/News/NewsContent'
import ArticleContent from './Components/tsx/Article/ArticleContent';
import Articles from './Components/tsx/Article/Articles';
import './App.css';
import { ThemeContext } from './contexts/theme-context';
import Layout from './Components/tsx/Layout';
import Login from './Components/tsx/Login/Login';
import Register from './Components/tsx/Login/Register'
import Search from './Components/tsx/Search';
import Game from './Components/tsx/Game';
import Profile from './Components/tsx/Profile/Profile';
import TagPage from './Components/tsx/Tag/TagPage';

import OneSignal from 'react-onesignal';
import { Store, StoreProvider } from './Components/tsx/Store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Activation from './Components/tsx/Login/Activation';

export default async function runOneSignal() {
  await OneSignal.init({ appId: '7257209c-4f1e-4515-ae44-01ad5b7d2c8b', allowLocalhostAsSecureOrigin: true});
  OneSignal.showSlidedownPrompt();
}
function App() {
  const [theme, setTheme] = useState('light');
  const location = useLocation();
  const [articleData, setArticleData] = useState(null);
  runOneSignal();
  useEffect(() => {

    const script = document.createElement('script');

    script.innerHTML = "";
    const screenWidth = window.innerWidth;
    const isMobileDevice = screenWidth <= 768
    var articleId = 0
    var pageType = ""
    var keyWords : any[] = []
    var category = ""
    pageType = "mainpage"
    if (articleData != null) {
      articleId = articleData["id"]
      keyWords = articleData["tags"]
      if(articleData["fields"]["cimke_oldalon_lathato"] != null){
        category = articleData["fields"]["cimke_oldalon_lathato"]
        category = category.split(":")[0]
      }
      pageType = "article"
    } else {
      pageType = "mainpage"
      keyWords = []
      category = ""
      if(location.pathname != "/") {
        pageType = "other"
      }
    }
    let deviceType = isMobileDevice ? "mobile" : "desktop"
    script.type = 'application/bannerData';
    script.id = 'adData';
    if (keyWords.length > 0){
      script.innerHTML = `
      {
        "url"         : "https://playstationcommunity.hu${location.pathname}",
        "device type" : "${deviceType}",
        "uid"         : "${articleId}",
        "pageType"    : "${pageType}",
        "keywords"    : "[${keyWords.map(item => item["name"])}]",
        "category"    : "${category}",
        "adult"       : 0,
        "gSens"       : 0
      }
    `;
    } else {
      script.innerHTML = `
      {
        "url"         : "https://playstationcommunity.hu${location.pathname}",
        "device type" : "${deviceType}",
        "uid"         : "${articleId}",
        "pageType"    : "${pageType}",
        "keywords"    : "",
        "category"    : "${category}",
        "adult"       : 0,
        "gSens"       : 0
      }
    `;
    }
    
    
    document.head.appendChild(script);
    
    return () => {
      // Clean up the script when the component unmounts
      document.head.removeChild(script);
    };
  }, [location, articleData])
  return (
    <GoogleOAuthProvider clientId="121197285094-7tpl0tc26s8mjo48ga9kcvtk9a1ek3eb.apps.googleusercontent.com">
    <StoreProvider>
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={`theme-${theme === undefined ? "light" : theme}`}>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="tagok/:id" element={<Profile />} />
            <Route path="tag/:slug/:tag" element={<TagPage />} />
            <Route path="hirek" element={<News />} />
            <Route path="hirek">
              <Route path=":cat/:id" element={<NewsContent setArticleData={setArticleData}/>} />
            </Route>
            <Route path="hirek">
              <Route path=":id" element={<NewsContent setArticleData={setArticleData}/>} />
            </Route>

            <Route path="cikkek" element={<Articles />} />
            <Route path="cikkek">
              <Route path=":id" element={<ArticleContent setArticleData={setArticleData}/>} />
            </Route>
            <Route path="kereses" element={<Search />} />
            <Route path="/game/konami/psc" element={<Game />} />
            <Route path="/:id" element={<ArticleContent setArticleData={setArticleData}/>} />
            <Route path="/bejelentkezes" element={<Login />} />
            <Route path="/regisztracio" element={<Register />} />
            <Route path="/aktivalas/:activation" element={<Activation />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/notfound" element={<NotFound />} />
          </Routes>
        </Layout>
      </div>
    </ThemeContext.Provider>
    </StoreProvider>
    </GoogleOAuthProvider>
  );
}

export function WrappedApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
