import React, { createContext, useState, ReactNode } from 'react';

interface StoreProps {
    myVariable: any[];
  setMyVariable: (value: any[]) => void;
}

export const Store = createContext<StoreProps>({
    myVariable: [],
  setMyVariable: () => {}
});

interface StoreProviderProps {
  children: ReactNode;
}

export const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
  const [myVariable, setMyVariable] = useState<any[]>([]);

  return (
    <Store.Provider value={{ myVariable, setMyVariable }}>
      {children}
    </Store.Provider>
  );
};
