import React, { useState, useEffect } from 'react';
import ArticleBackgroundLoop from "./ArticleBackgroundLoop"
import axios from 'axios';
import LoadingSpinner from '../LoadingSpinner';

export default function Articles() {
    document.title = "Cikkek - PlayStationCommunity.hu"

    var [Page, updatePages] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false);
    var [pagination, setPagination] = useState(1);
    const [isSearch, setIsSearch] = useState(false);
    const [search, setSearch] = useState("");


    const handlePagination = (e: string) => {
        if (e === "back") {
            if (pagination !== 1) {
                setPagination(pagination - 1)
            }

        }
        if (e === "next") {
            setPagination(pagination + 1)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        setIsLoading(true)
        var userId = ""
        if(localStorage.getItem("userId") != null){
            userId = "&user=" + localStorage.getItem("userId")
        }
        if(isSearch === false){
            axios
            .get("https://playstationcommunity.hu/wp-json/kingweb/v1/id?type=page&limit=30&page=" + pagination + userId)
            .then((res) => {
                updatePages(res.data)
                setIsLoading(false)
            })
        } else {
            axios
            .get("https://playstationcommunity.hu/wp-json/kingweb/v1/id?type=page&limit=30&search=" + search + "&page=" + pagination+ userId)
            .then((res) => {
                updatePages(res.data)
                setIsLoading(false)
            })
        }
    }, [pagination, isSearch])
    const handleSearch = () => {
        setIsSearch(true)
        setPagination(1)
    }
    const deleteSearch = () => {
        setSearch("")
        setPagination(1)
        setIsSearch(false)
    }

    if (isLoading) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        )
    } else {
        return (
            <div>
                <div className='block'>
                    <h1>Cikkek</h1>
                    <input placeholder="Írd be mit szeretnél keresni" className="searchInput" type="text" onChange={e => setSearch(e.target.value)}></input><button className="searchButton" onClick={e => handleSearch()}>Keresés</button><br></br>
                    <div id="searchs" style={isSearch ? {display: "inline-flex"} : {display: "none"}}><p className='searchedtext'>Keresett kifejezés: {search}</p><button onClick={deleteSearch}>keresés törlése</button></div>
                    <div>
                    <div className='paginationDiv'><button style={pagination > 1 ? {display: "inline-block"} : {display: "none"}} className='paginationButton' onClick={e => handlePagination("back")}>&lt;</button>{pagination}. oldal<button style={Page.length > 15 ? {display: "inline-block"} : {display: "none"}} className='paginationButton' onClick={e => handlePagination("next")}>&gt;</button></div>
                        {Page.map((val, key) => {
                            return (
                                <div key={key} className='newsBlock articleLoop'>
                                    {ArticleBackgroundLoop(val)}
                                </div>
                            )
                        })}
                    <div className='paginationDiv'><button style={pagination > 1 ? {display: "inline-block"} : {display: "none"}} className='paginationButton' onClick={e => handlePagination("back")}>&lt;</button>{pagination}. oldal<button style={Page.length > 15 ? {display: "inline-block"} : {display: "none"}} className='paginationButton' onClick={e => handlePagination("next")}>&gt;</button></div>
                    </div>
                </div>
            </div>
        )
    }
}